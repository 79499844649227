import { Store } from '../Store'

const GET = (url, retried)=>{
  return new Promise((resolve, reject)=>{
    fetch(url, {
      method: 'GET'
    }).then((response)=>{
      handleGETResponse(response, resolve, reject, retried, ()=>{
        GET(url, true).then(resolve)
      })
    }).catch(reject)
  }) 
}

const handleGETResponse = (response, resolve, reject, retried, retry)=>{
  if(response.status == 200) {
    response.text().then((text)=>{
      try {
        let json = JSON.parse(text)
        resolve(json)
      } catch {
        resolve(text)
      }
    }).catch(reject)
  } else if ([201, 204].includes(response.status)) {
    resolve()
  } else if (response.status == 404) {
    resolve()
  } else if (response.status == 401) {
    attemptSessionRefresh().then(()=>{
      if(retry && !retried) {
        retry()
      } else {
        handleUnauthorized()
      }
    }).catch(handleUnauthorized)
  } else {
    response.text().then((text)=>{
      reject(text || 'Request failed')
    }).catch(reject)
  }
}

const POST = (url, body, retried)=>{
  return new Promise((resolve, reject)=>{
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": Store.getState()?.csrf_token?.token || document.querySelector('[name=csrf-token]').content
      }
    }).then((response)=>{
      handlePOSTResponse(response, resolve, reject, retried, ()=>{
        POST(url, body, true).then(resolve)
      })
    }).catch(reject)
  }) 
}

const handlePOSTResponse = (response, resolve, reject, retried, retry)=>{
  if(response.status == 200) {
    response.text().then((text)=>{
      try {
        let json = JSON.parse(text)
        resolve(json)
      } catch {
        resolve(text)
      }
    }).catch(reject)
  } else if ([201, 204].includes(response.status)) {
    resolve()
  } else if (response.status == 401) {
    attemptSessionRefresh().then(()=>{
      if(retry && !retried) {
        retry()
      } else {
        handleUnauthorized()
      }
    }).catch(handleUnauthorized)
  } else {
    if(response.status == 402) {
      Store.dispatch(({ type: 'paywall/show' }))
      reject('Subscription Required')
    } else {
      response.text().then((text)=>{
        reject(text || 'Request failed')
      }).catch(reject)
    }
  }
}

const DELETE = (url, retried)=>{
  return new Promise((resolve, reject)=>{
    fetch(url, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": Store.getState()?.csrf_token?.token || document.querySelector('[name=csrf-token]').content
      }
    }).then((response)=>{
      handleDELETEResponse(response, resolve, reject, retried, ()=>{
        DELETE(url, true).then(resolve)
      })
    }).catch(reject)
  }) 
}

const handleDELETEResponse = (response, resolve, reject, retried, retry)=>{
  if(response.status == 200) {
    response.text().then((text)=>{
      try {
        let json = JSON.parse(text)
        resolve(json)
      } catch {
        resolve(text)
      }
    }).catch(reject)
  } else if ([201, 204].includes(response.status)) {
    resolve()
  } else if (response.status == 401) {
    attemptSessionRefresh().then(()=>{
      if(retry && !retried) {
        retry()
      } else {
        handleUnauthorized()
      }
    }).catch(handleUnauthorized)
  } else {
    response.text().then((text)=>{
      reject(text || 'Request failed')
    }).catch(reject)
  }
}

let attemptSessionRefreshPromise = null
const attemptSessionRefresh = ()=>{
  if(!attemptSessionRefreshPromise) {
    attemptSessionRefreshPromise = new Promise((resolve, reject)=>{
      const refreshToken = Store.getState().refresh_token?.token
      if(refreshToken && refreshToken.length) {
        fetch('/api/login/refresh', {
          method: 'POST',
          body: JSON.stringify({ refresh_token: refreshToken }),
          headers: { "Content-Type": "application/json" }
        }).then((response)=>{
          response.json().then((responseData)=>{
            Store.dispatch(({ type: 'user/set', user: responseData.user }))
            Store.dispatch(({ type: 'refreshToken/set', token: responseData.refresh_token }))
            Store.dispatch(({ type: 'csrfToken/set', token: responseData.csrf_token }))
            resolve()
            attemptSessionRefreshPromise = null
          }).catch(()=>{
            reject()
            attemptSessionRefreshPromise = null
          })
        }).catch(()=>{
          reject()
          attemptSessionRefreshPromise = null
        })
      } else {
        throw new Error('Refresh token not found!')
      }
    })
  }

  return attemptSessionRefreshPromise
}

let handleUnauthorizedPromise = null
const handleUnauthorized = async ()=>{
  if(!handleUnauthorizedPromise) {
    localStorage.removeItem('persist:app')
    window.DePayWebAppRoot.unmount()
    window.location = '/'
    window.location.reload(true)
    handleUnauthorizedPromise = null
  }

  return handleUnauthorizedPromise
}

export { GET, POST, DELETE }
