export default function AccountsReducer(state = [], action) {
  let newState
  switch (action.type) {
    case 'accounts/add':
      newState = (state || []).slice()
      newState.byId = {}
      newState.push(action.account)
      newState.byId[action.account.id] = action.account
      return newState
    case 'accounts/set':
      newState = action?.accounts?.slice() || []
      newState.byId = {}
      newState.forEach((account)=>{
        newState.byId = { [account.id]: account }
      })
      return newState
    case 'accounts/update':
      newState = (state || []).slice()
      let updatedLinkIndex = newState.findIndex((i)=>action.account.id==i.id)
      if(updatedLinkIndex > -1){
        newState[updatedLinkIndex] = action.account
        newState.byId = newState.byId || {}
        newState.byId[action.account.id] = action.account
      }
      return newState
    case 'accounts/delete':
      let deletedAccountWallet
      newState = state.filter((account)=>{
        if(account.id == action.id) {
          deletedAccountWallet = account
          return false
        } else {
          return true
        }
      })
      return newState
    default:
      return state
  }
}
