import "../stylesheets/application"
import "@fortawesome/fontawesome-free/css/all"
import 'bootstrap/dist/js/bootstrap.bundle.js'
import "channels"
import App from "../App"
import DePayWidgets from '@depay/widgets'
import React from "react"
import ScrollToTop from "../components/ScrollToTop"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { createRoot } from "react-dom/client"
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { Store, Persistor } from '../Store'

window.DePayWidgets = DePayWidgets

document.addEventListener('DOMContentLoaded', () => {

  window.DePayWebAppRoot = createRoot(document.getElementById('app'))

  window.DePayWebAppRoot.render(
    <Provider store={ Store }>
      <PersistGate loading={null} persistor={Persistor}>
        <Router>
          <ScrollToTop>
            <App/>
          </ScrollToTop>
        </Router>
      </PersistGate>
    </Provider>
  )
})
