import CopyButton from "../../components/CopyButton"
import QRCodeStyling from "qr-code-styling"
import React, { useEffect, useState, useRef } from "react"
import UserSettingsSidebar from '../../components/UserSettingsSidebar'
import { POST } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'

export default function(props) {

  const user = useSelector(state => state.user)
  const [ error, setError ] = useState()
  const [ secret, setSecret ] = useState()
  const [ otp, setOtp ] = useState()
  const [ qrCode, setQrCode ] = useState()
  const qrCodeRef = useRef()
  const navigate = useNavigate()

  const storeOTP = ()=>{
    POST('/api/user/2fa', { secret, otp }).then(()=>{
      Store.dispatch({ type: 'user/change', user: { otp: true } })
      navigate('/user/authentication')
    }).catch((response)=>{
      let responseData
      try { responseData = JSON.parse(response) } catch {}
      setError(responseData?.error || 'An error occured!')
    })
  }

  useEffect(()=>{
    POST('/api/user/2fa').then((otp)=>{
      if(otp && otp.secret && otp.secret.length) {

        let newQrCode = new QRCodeStyling({
          width: 600,
          height: 600,
          dotsOptions: {
            type: "square"
          },
        })
        newQrCode.update({ data: `otpauth://totp/DePay:${user.name || 'User'}?secret=${otp.secret}&issuer=DePay` })
        setQrCode(newQrCode)
        setSecret(otp.secret)
      }
    })
  }, [])

  useEffect(()=>{
    if(qrCode && qrCodeRef && qrCodeRef.current) {
      qrCodeRef.current.innerHTML = ""
      qrCode.append(qrCodeRef.current)
    }
  }, [qrCode])

  if(!secret) { return null }

  return(

    <div className="user-2fa-screen row">

      <div className="col-12 col-md-3 px-2 px-lg-0 order-md-1">
        <UserSettingsSidebar/>
      </div>

      <div className="d-block col-12 col-md-9 pt-2 pt-md-5 px-2 px-md-5 ps-md-3 pr-md-0 d-md-flex justify-content-end">

        <div className="col-lg-8">

            <h5 className="pt-3 ps-1 pb-1">Setup Two Factor Authentication</h5>     

            <div className="rounded-2 bg-white p-3 mb-2 mt-2 text-center">

              <div className="py-1 text-start">
                <span className="fw-bold">
                  1. Scan this code with your authenticator app
                </span>
              </div>

              <div className="py-2 px-2 px-md-4">
                <div className="ratio ratio-1x1" ref={ qrCodeRef } />
              </div>

              <CopyButton value={ secret } />

              <div className="py-1 px-3 lh-sm font-size-s">
                <small className="text-muted">
                  <i className="fas fa-info-circle me-2"></i>
                  Ensure you securely store a backup of this code in case you lose access to your authenticator app.
                </small>
              </div>

            </div>

            <div className="rounded-2 bg-white p-3 mb-2 mt-2">

              <label htmlFor="otp" className="py-1">
                <span className="fw-bold">
                  2. Enter the code from your authenticator app
                </span>
              </label>

              <form className="pt-2 text-center" onSubmit={(e)=>{ e.preventDefault(); storeOTP(); return false; }}>
                <div>
                  <input name="otp" value={otp || ''} onChange={(event)=>setOtp(event.target.value)} placeholder="316512" required className="row bg-white rounded-2 mb-2 p-2 px-2 form-control form-control-lg text-center" type="text"/>
                </div>
                {
                  error &&
                  <div className="alert alert-danger py-2">
                    { error }
                  </div>
                }
                <div>
                  <button type="submit" className="btn btn-primary btn-lg rounded-2 w-100">
                    Submit
                  </button>
                </div>
              </form>

            </div>
        </div>
      </div>
    </div>
  )
}
