import { Store } from '../Store'
import { POST } from '../helpers/Queries'

const postLogout = ()=>{
  localStorage.removeItem('persist:app')
  window.DePayWebAppRoot.unmount()
  window.location = '/'
}

export default function(){
  POST('/api/logout').then(postLogout).catch(postLogout)
}
